/* .column {
  float: left;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
} */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.inline {
  position: relative;
  text-align: center;
}
.myborder {
  border: 5px solid lightgrey;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  /*border-color: black;*/
  
}
.centered {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  
}
div.spinner{
  top: 50%
}
.btnwide {
  width: 100%;
  color: black;
  font-weight: bold;
}