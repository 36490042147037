.App {
  text-align: center;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

input {
  width: 100%;
}

h3, h4, h5, h6, hr {
  margin: 0px;
}

.spinner {
  position: absolute;
  left: 48%;
  top: 23%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left:6px solid rgba(12, 107, 36, 0.15);
  border-right:6px solid rgba(12, 107, 36, 0.15);
  border-bottom:6px solid rgba(12, 107, 36, 0.15);
  border-top:6px solid rgba(12, 107, 36, 0.842);
  border-radius:100%;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

#overlay{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 500;
}