.invoice {
  font-family: sans-serif;
  font-size: 12px;
}
.redBar {
  background-color: green !important;
}

@media print {
  .myDiv {
     break-inside: avoid;
     padding-top: 30px;
  }
}
/* @page 
{ 
    margin: 10; 
} */
/* @media print {
  p {page-break-inside: avoid;}
  h1 {page-break-before: always;}
  footer {page-break-after: always;}
} */